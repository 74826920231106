import React from "react";
import { HashRouter, Switch, Route, Router } from "react-router-dom";
import "./App.css";
import Home from "./components/Home/home.js";
import Tutoring from "./components/Tutoring/tutoring.js";
import Performing from "./components/Performing/performing.js";
import Supporting from "./components/Supporting/supporting.js";
import Schools from "./components/Schools/schools.js";
import ScrollToTop from "./ScrollToTop";

//<Route path="/create" component={Create} />

const App = () => (
  <div className="app">
    <HashRouter>
      <ScrollToTop>
        <Switch>
          <Route exact path="/" component={Home} />
        </Switch>
        <Switch>
          <Route path="/tutoring" component={Tutoring} />
        </Switch>
        <Switch>
          <Route path="/schools" component={Schools} />
        </Switch>
        <Switch>
          <Route path="/performing" component={Performing} />
        </Switch>
        <Switch>
          <Route path="/supporting" component={Supporting} />
        </Switch>
      </ScrollToTop>
    </HashRouter>
  </div>
);

export default App;
