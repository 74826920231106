import React, { Component } from "react";
import { Link } from "react-router-dom";

import "./style.css";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  toggleMenu() {
    document.querySelector(".mobileMenu").classList.toggle("activeMenu");
  }

  render() {
    return (
      <div>
        <div className="headerContainer-desktop">
          <Link to="/tutoring">
            <div className="menuItem">Tutoring</div>
          </Link>
          <Link to="/schools">
            <div className="menuItem">School Teaching</div>
          </Link>
          <Link to="/">
            <div className="logo">
              Jess
              <br />
              Martin
            </div>
          </Link>
          <Link to="/performing">
            <div className="menuItem">Performing</div>
          </Link>
          <Link to="/supporting">
            <div className="menuItem">Outreach</div>
          </Link>
        </div>

        <div className="headerContainer-mobile">
          <Link to="/">
            <div className="logo">
              Jess
              <br />
              Martin
            </div>
          </Link>
          <div className="headerMenuIcon" onClick={this.toggleMenu}></div>
        </div>

        <div className="mobileMenu">
          <Link to="/tutoring">
            <div className="mobileMenuItem">Tutoring</div>
          </Link>
          <Link to="/schools">
            <div className="mobileMenuItem">Schools</div>
          </Link>
          <Link to="/performing">
            <div className="mobileMenuItem">Performing</div>
          </Link>
          <Link to="/supporting">
            <div className="mobileMenuItem">Outreach</div>
          </Link>
        </div>
      </div>
    );
  }
}
export default Header;
