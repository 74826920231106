import React, { Component } from "react";

class CarouselItem extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="carousel-item-container">
        <div
          className="carouselitem-icon"
          style={{ backgroundImage: "url(" + this.props.assetPath + ")" }}
        ></div>
        <div className="carouselitem-title">{this.props.title}</div>
        <div className="carouselitem-desc">{this.props.desc}</div>
        <div className="carouselitem-price">
          <div>
            <sup>£</sup>
            <span>40</span>
          </div>
          <div>Per Hour</div>
        </div>
      </div>
    );
  }
}
export default CarouselItem;
