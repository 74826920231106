import React, { Component } from "react";
import TestimonialItem from "./testimonialItem.js";
import Carousel, { slidesToShowPlugin } from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";

class CarouselContainer extends Component {
  constructor(props) {
    super(props);
    this.state = { value: 0 };
  }

  render() {
    return (
      <Carousel slidesPerPage={1} infinite arrows>
        <TestimonialItem
          Desc={
            "Jess is a pleasure to have as my daughter’s saxophone teacher. She’s always prompt to respond, arrives punctually and very willing to give advice regarding the saxophone. I would definitely recommend her as a teacher."
          }
          name={"– Tina"}
        />
        <TestimonialItem
          Desc={
            "I started learning the saxophone 3 years ago and I can’t recommend Jess highly enough!  She is always so patient, friendly and precise. We always do a diverse mixture of things in lessons: from duets to songs and also more traditional pieces - there is never a moment where learning the saxophone isn’t fun. In 3 years I’ve managed to learn so much (I’m now on grade 6) and it’s been really great :)"
          }
          name={"– Aarthi"}
        />
      </Carousel>
    );
  }
}
export default CarouselContainer;
