import React, { Component } from "react";
import CarouselItem from "./carouselItem.js";
import Carousel, { slidesToShowPlugin } from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";

import recorderImage from "../../images/tutoring-recorder.png";
import saxImage from "../../images/tutoring-sax.png";
import fluteImage from "../../images/tutoring-flute.png";
import clarinetImage from "../../images/tutoring-clarinet.png";

class CarouselContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      carouselNumber: 4,
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  }

  resize() {
    console.log("RESIZE!");
    if (window.innerWidth <= 760) {
      console.log("mobile");
      this.setState({ carouselNumber: 1 });
    } else if (window.innerWidth <= 1000) {
      this.setState({ carouselNumber: 3 });
    } else {
      this.setState({ carouselNumber: 4 });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize.bind(this));
  }

  render() {
    return (
      <div className="carousel">
        <Carousel slidesPerPage={this.state.carouselNumber} infinite arrows>
          <CarouselItem
            assetPath={saxImage}
            title={"Saxophone"}
            desc={
              "Classes are available to students at all ages and levels from beginners to grade 8 and beyond!"
            }
          />
          <CarouselItem
            assetPath={fluteImage}
            title={"Flute"}
            desc={
              "Classes are available to students at all ages and levels from beginners to grade 8 and beyond!"
            }
          />
          <CarouselItem
            assetPath={clarinetImage}
            title={"Clarinet"}
            desc={
              "Classes are available to students at all ages and levels from beginners to grade 8 and beyond!"
            }
          />
          <CarouselItem
            assetPath={recorderImage}
            title={"Recorder"}
            desc={
              "Classes are available to students at all ages and levels from beginners to grade 5 on descant recorder."
            }
          />
        </Carousel>
      </div>
    );
  }
}
export default CarouselContainer;
