import React, { Component } from "react";
import { Link } from "react-router-dom";
import FooterBanner from "./footer-banner.js";
import "./style.css";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="footerContainer">
        <FooterBanner></FooterBanner>
        <div className="footerFlex">
          <Link to="/tutoring">
            <div className="menuItem">Tutoring</div>
          </Link>
          <Link to="/schools">
            <div className="menuItem">School Teaching</div>
          </Link>
          <Link to="/performing">
            <div className="menuItem">Performing</div>
          </Link>
          <Link to="/supporting">
            <div className="menuItem">Outreach</div>
          </Link>
        </div>
        <div className="footerLogo">
          <div className="footerLine"></div>
          <Link to="/">
            <div className="footerLogoText">
              Jess
              <br />
              Martin
            </div>
          </Link>
          <div className="footerLine"></div>
        </div>
      </div>
    );
  }
}
export default Footer;
