import React, { Component } from "react";
import { Link } from "react-router-dom";
const axios = require("axios");
let access_token =
  "EAAFwCZB3ZA6YMBAFcveZAPBeZCZAMij6e8QjBxY1AiWAmUtHwLaKMPsAjxJ3AwF8vdbrnvR59doIPOTVt0FShdh4JKLHY34lxqt5U5p2zPXZAkNztpglgw37J1b8ZAFg2iXPyoS64TqLZBokCDPZAGHZAmaKKpxbjOMAmVtZC1C5lUlUQZDZD";

class SocialFeed extends Component {
  constructor(props) {
    super(props);
    this.state = {
      facebookPosts: [],
    };
  }

  componentWillMount() {
    let currentComponent = this;
    axios
      .get(
        "https://graph.facebook.com/v14.0/367403950777324/photos?fields=images,name&access_token=" +
          access_token
      )
      .then(function (response) {
        console.log(response);
        response.data.data[3].name = response.data.data[3].name.substring(
          0,
          100
        );
        response.data.data[4].name = response.data.data[4].name.substring(
          0,
          100
        );

        currentComponent.setState({ facebookPosts: response.data.data });
        console.log(this.state.facebookPosts);
        console.log("test");
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  render() {
    if (this.state.facebookPosts.length > 0) {
      return (
        <div className="instaContainer">
          <div
            className="image fb-small"
            style={{
              backgroundImage:
                "url(" + this.state.facebookPosts[0].images[0].source + ")",
            }}
          >
            <div className="fb-captionContainer">
              <div className="facebookIcon">Facebook</div>
              <div className="fb-caption">
                {this.state.facebookPosts[0].name}
              </div>
              <div className="fb-date">- 7th May 2020</div>
              <div className="fb-arrow"></div>
            </div>
          </div>
          <div
            className="image fb-small"
            style={{
              backgroundImage:
                "url(" + this.state.facebookPosts[1].images[0].source + ")",
            }}
          >
            <div className="fb-captionContainer">
              <div className="facebookIcon">Facebook</div>
              <div className="">{this.state.facebookPosts[1].name}</div>
              <div className="fb-date">- 7th May 2020</div>
              <div className="fb-arrow"></div>
            </div>
          </div>
          <div
            className="image fb-long"
            style={{
              backgroundImage:
                "url(" + this.state.facebookPosts[2].images[0].source + ")",
            }}
          >
            <div className="fb-captionContainer">
              <div className="facebookIcon">Facebook</div>
              <div className="">{this.state.facebookPosts[2].name}</div>
              <div className="fb-date">- 7th May 2020</div>
              <div className="fb-arrow"></div>
            </div>
          </div>
          <div
            className="image fb-long"
            style={{
              backgroundImage:
                "url(" + this.state.facebookPosts[3].images[0].source + ")",
            }}
          >
            <div className="fb-captionContainer">
              <div className="facebookIcon">Facebook</div>
              <div className="">{this.state.facebookPosts[3].name}</div>
              <div className="fb-date">- 7th May 2020</div>
              <div className="fb-arrow"></div>
            </div>
          </div>
          <div
            className="image fb-small"
            style={{
              backgroundImage:
                "url(" + this.state.facebookPosts[4].images[0].source + ")",
            }}
          >
            <div className="fb-captionContainer">
              <div className="facebookIcon">Facebook</div>
              <div className="">{this.state.facebookPosts[4].name}</div>
              <div className="fb-date">- 7th May 2020</div>
              <div className="fb-arrow"></div>
            </div>
          </div>
          <div>
            <div className="social-blob"></div>
            <div className="image fb-small boxShadow mobile-social">
              <div className="fb-headline">
                Follow me on social media
                <div className="social-icon facebook"></div>
                <div className="social-icon instagram"></div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}
export default SocialFeed;
