import React, { Component } from "react";
import Header from "../Header/header";
import Footer from "../Footer/footer";
import PageHeading from "../Home/pageHeading";
import CarouselContainer from "./carousel.js";
import TestimonialContainer from "./testimonials.js";
import "./style.css";

class Tutoring extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <Header></Header>
        <div className="pageContainer tutoring">
          <PageHeading title="Tutoring"></PageHeading>
          <p>
            Learning an instrument is a valuable skill. Private tutoring allows
            you to learn at your own pace and master your new talent from any
            level.
          </p>
        </div>

        <div className="pageContainer carouselSection">
          <PageHeading title="Instruments Taught"></PageHeading>
          <div className="subHeadDesc">
            I take pride in creating fun and interactive lessons that engage and
            challenge budding musicians of all ages and abilities. I have a
            wealth of experience preparing students for exams, with a strong
            record, should this be a goal of a student.
          </div>
        </div>
        <CarouselContainer></CarouselContainer>
        <div className="pageContainer initialHeight">
          <div className="music0-is-more-than-a-passion">
            <div className="img"></div>
            <div className="flex-container">
              <div className="title">Music should be fun.</div>
              <div className="desc">
                Too often, tutors of instruments can take the fun out of
                learning leading to a loss in passion, so I have asked past
                clients to leave reviews below.
              </div>
            </div>
          </div>
        </div>
        <div className="pageContainer initialHeight initialTop">
          <PageHeading title="Testimonials"></PageHeading>
          <div className="testimonial-item-container">
            <TestimonialContainer></TestimonialContainer>
          </div>
        </div>

        <div className="pageContainer contact-form">
          <div className="ellipse3"></div>
          <div className="bookingFormContainer">
            <div className="bookingForm">
              <div className="title">Booking Form</div>
              <div className="desc">
                Teaching music isnt just a hobby, its a full-time job that i
                love. Working across London with a variety of skill levels and
                age groups.
              </div>
              <input
                type="text"
                className="text-input name"
                placeholder="Full Name"
              ></input>
              <input
                type="text"
                className="text-input email"
                placeholder="Email Address"
              ></input>
              <input
                type="text"
                className="text-input number"
                placeholder="Phone Number"
              ></input>
              <input
                type="text"
                className="text-input instrument"
                placeholder="Instrument Choice"
              ></input>
              <div className="cta">Book Now</div>
            </div>
            <div className="img"></div>
          </div>
        </div>

        <Footer></Footer>
      </div>
    );
  }
}
export default Tutoring;
