import React, { Component } from "react";

class FooterBanner extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="footer-banner-container">
        <div className="footer-banner-flex">
          <h3>Have any Questions?</h3>
          <p>
            If you would like to find out more, have any questions or like to
            book a class, then please get in-touch.
          </p>
        </div>
        {/* <div className="footer-banner-cta">Contact</div> */}
        <div className="footer-banner-blob"></div>
      </div>
    );
  }
}
export default FooterBanner;
