import React, { Component } from "react";
import Header from "../Header/header";
import Footer from "../Footer/footer";
import PageHeading from "../Home/pageHeading";
import CarouselContainer from "./carousel.js";
import "./style.css";

class Schools extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <Header></Header>
        <div className="pageContainer schools">
          <PageHeading title="School Teaching"></PageHeading>
          <p>
            Working all over London, I teach for multiple music services and
            institutions such as Enfield and Haringey Music Service, Tri Borough
            Music Hub and Sydenham School. I take pride in creating lessons that
            engage and challenge budding musicians of all ages and abilities.
          </p>
        </div>
        <div className="pageContainer initialHeight marginTopOverride">
          <div className="ellipse-teaching-top"></div>
          <div className="teaching-classes">
            <div className="classes-top-row">
              <div className="single main">
                <h3>Classes</h3>
                <p>
                  With many years of experience teaching school classes from
                  nursery to year 6, delivering different programmes depending
                  on the age of the year group.
                </p>
              </div>
              <div className="double">
                <h3>
                  From singing, playing
                  <br />
                  instruments and
                  <br />
                  elements of music theory.
                </h3>
                <p>
                  All of the programmes can be adapted to a<br />
                  schools needs and the resources available.
                </p>
                <div className="top-double-flex">
                  <div className="double-info-container">
                    <p className="desc">
                      Music classes
                      <br />
                      last approximately:
                    </p>
                    <p className="time">
                      45-60 <span>mins</span>
                    </p>
                  </div>
                  <div className="classes-icon teaching-classes-four"></div>
                </div>
              </div>
            </div>
            <CarouselContainer></CarouselContainer>
            {/* <div className="classes-bottom-row"> */}

            {/* <div className="single">
                <div className="classes-icon teaching-classes-one"></div>
                <h3>
                  Ukulele Group
                  <br />
                  Sessions
                </h3>
                <p>
                  Brief Description of what tutorship entails. Around about 2 -
                  3 sentences to help explain what happens in the hour & what to
                  expect.
                </p>
              </div>
              <div className="single">
                <div className="classes-icon teaching-classes-two"></div>
                <h3>
                  Woodwind Group
                  <br />
                  Sessions
                </h3>
                <p>
                  Brief Description of what tutorship entails. Around about 2 -
                  3 sentences to help explain what happens in the hour & what to
                  expect.
                </p>
              </div> */}
            {/* <div className="single">
                <div className="classes-icon teaching-classes-three"></div>
                <h3>
                  General Music
                  <br />
                  Sessions
                </h3>
                <p>
                  Brief Description of what tutorship entails. Around about 2 -
                  3 sentences to help explain what happens in the hour & what to
                  expect.
                </p>
              </div> */}
            {/* </div> */}
          </div>
        </div>

        <div className="pageContainer initialHeight">
          {/* <div className="ellipse-teaching-middle"></div> */}
          <div className="ensembles-and-choirs reverse">
            <div className="img choir"></div>
            <div className="flex-container">
              <div className="title reverse">
                Orchestras,
                <br />
                Ensembles
                <br />& Choirs
              </div>
              <div className="desc reverse">
                Organising an orchestra, ensemble or choir can be a daunting
                process, and even harder to pull off successfully. I am
                available to direct, support and bring your schools musical
                ambition to the fore. Whether for a competition, private event,
                a school production or simply for fun!
              </div>
            </div>
          </div>
        </div>
        {/* <div className="pageContainer initialHeight">
          <div className="ensembles-and-choirs">
            <div className="img lesson"></div>
            <div className="flex-container">
              <div className="title">
                Need some
                <br />
                lesson inspiration?
              </div>
              <div className="desc">
                Mauris lobortis magna ut lacus dignissim, eget lobortis turpis
                laoreet. Nam sed molestie velit. Aliquam sem elit, luctus eu
                consequat sodales, mollis non quam. Quisque tincidunt sit amet
                purus nec euismod. luctus eu consequat sodales, mollis non quam.
                Quisque tincidunt sit amet purus nec euismod.
              </div>
              <div className="button">Download Plan</div>
            </div>
          </div>
        </div> */}
        <div className="pageContainer contact-form">
          <div className="bookingFormContainer">
            <div className="bookingForm">
              <div className="title">Booking Form</div>
              <div className="desc">
                Teaching music isnt just a hobby, its a full-time job that i
                love. Working across London with a variety of skill levels and
                age groups.
              </div>
              <input
                type="text"
                className="text-input name"
                placeholder="Full Name"
              ></input>
              <input
                type="text"
                className="text-input email"
                placeholder="Email Address"
              ></input>
              <input
                type="text"
                className="text-input number"
                placeholder="Phone Number"
              ></input>
              <input
                type="text"
                className="text-input instrument"
                placeholder="Instrument Choice"
              ></input>
              <div className="cta">Book Now</div>
            </div>
            <div className="img"></div>
          </div>
        </div>

        <Footer></Footer>
      </div>
    );
  }
}
export default Schools;
