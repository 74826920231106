import React, { Component } from "react";
import Header from "../Header/header";
import Footer from "../Footer/footer";
import PageHeading from "../Home/pageHeading";
import "./style.css";

class Performing extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <Header></Header>
        <div className="pageContainer tutoring">
          <PageHeading title="Performing"></PageHeading>
          <p>
            Having Toured Spain, Slovakia and the UK, I have a wealth of
            performance experience both solo and with ensembles such as Parallax
            Saxophone Quartet and Street Orchestra Live.
          </p>
        </div>
        <div className="pageContainer initialHeight">
          <div className="performing-passion">
            <div className="img"></div>
            <div className="flex-container">
              <div className="title">
                Music is more
                <br />
                than a passion
              </div>
              <div className="desc">
                I am a London based performer, available for functions,
                concerts, shows and events worldwide. As a tripler, playing
                flute, saxophone and clarinet, I have a love for music theatre
                and have experience playing on a variety of shows such as
                Chicago, Grease and Our House.
              </div>
            </div>
          </div>
        </div>

        <div className="pageContainer performanceContainer">
          <PageHeading title="Street Orchestra"></PageHeading>
          <div className="subHeadDesc">
            Working with Street Orchestra Live is another fantastic part of my
            job as a freelance musician. We travel the UK with a mission to take
            orchestral music to everyone, anywhere, offering concerts to
            communities with little or no opportunity to hear a live orchestra.
            We have played in hospitals, prisons, libraries, community centres,
            shopping centres and castles. Nowhere is off limits!
          </div>
        </div>
        <div className="performanceVideoContainer"></div>
        <Footer></Footer>
      </div>
    );
  }
}
export default Performing;
