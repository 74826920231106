import React, { Component } from "react";
import Header from "../Header/header";
import Footer from "../Footer/footer";
import PageHeading from "../Home/pageHeading";
import "./style.css";

class Performing extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <Header></Header>
        <div className="pageContainer tutoring">
          <PageHeading title="Outreach"></PageHeading>
          <p>
            Music is an incredible tool for bringing people together, helping
            people to communicate, supporting recovery and bringing joy to
            people’s lives. For many years, I have been involved in delivering
            outreach projects across a variety of settings including residential
            homes, community centres, schools and early years settings.
          </p>
        </div>
        <div className="pageContainer">
          <div className="flexContainer home">
            <div className="articleBox">
              <h2>Turtle Song</h2>
              <span className="articleContent">
                <p>
                  Working with people living with dementia is something that is
                  very close to my heart and a highlight of my job. For the past
                  six years, I have worked with Turtle Key Arts on a project
                  called Turtle Song, which brings music, song writing, movement
                  and singing to people with dementia together with their
                  companions and carers.
                </p>
                <p>
                  Now in its 13th year, Turtle Song encourages artistic and
                  social interaction, a positive outlook through an enjoyable
                  and shared activity and gives the brain and body a bit of
                  stimulating exercise.
                </p>
              </span>
              <a
                href="https://www.turtlekeyarts.org.uk/turtle-song"
                target="_blank"
              >
                <div className="turtle-key-arts-logo"></div>
              </a>
            </div>
            <div className="outreachImageContainer">
              <div className="imageOne"></div>
              <div className="imageTwo"></div>
              <div className="imageThree"></div>
            </div>
          </div>
        </div>
        <Footer></Footer>
      </div>
    );
  }
}
export default Performing;
