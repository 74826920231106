import React, { Component } from "react";
import CarouselItem from "./carouselItem.js";
import Carousel, { slidesToShowPlugin } from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";

import recorderImage from "../../images/tutoring-recorder.png";
import saxImage from "../../images/tutoring-sax.png";
import fluteImage from "../../images/tutoring-flute.png";
import clarinetImage from "../../images/tutoring-clarinet.png";
import musicBookImage from "../../images/homepage-book-blob.png";
import ukuleleImage from "../../images/ukulele.png";

class CarouselContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      carouselNumber: 4,
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  }

  resize() {
    console.log("RESIZE!");
    if (window.innerWidth <= 760) {
      console.log("mobile");
      this.setState({ carouselNumber: 1 });
    } else if (window.innerWidth <= 1000) {
      this.setState({ carouselNumber: 3 });
    } else {
      this.setState({ carouselNumber: 3 });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize.bind(this));
  }

  render() {
    return (
      <div className="school-carousel">
        <Carousel slidesPerPage={this.state.carouselNumber} infinite arrows>
          <CarouselItem
            assetPath={saxImage}
            title={"General Music Sessions"}
            desc={
              "Specialised music classes are fun, interactive and engaging and comply with the music curriculum. Classes are available from nursery to year 6 and are adapted and appropriate for the age range."
            }
          />
          <CarouselItem
            assetPath={fluteImage}
            title={"Woodwind Group Sessions"}
            desc={
              "Aimed at upper KS2, Woodwind Sessions give students the opportunity to learn the flute, saxophone or clarinet and to read music."
            }
          />
          <CarouselItem
            assetPath={recorderImage}
            title={"Recorder Group Sessions"}
            desc={
              "Recorder Group Sessions can be adapted to suit any year of KS2 giving students the opportunity to learn the recorder and how to read music."
            }
          />
          <CarouselItem
            assetPath={musicBookImage}
            title={"Music Theory Sessions"}
            desc={
              "Whether you want to improve your general music theory, or to work towards a theory exam, theory classes can increase your proficiency and boost your confidence!"
            }
          />
          <CarouselItem
            assetPath={ukuleleImage}
            title={"Ukulele Group Sessions"}
            desc={
              "Classes can be adapted to suit any age from year 1 up to year 6 giving students the opportunity learn the ukulele and sing along."
            }
          />
          <CarouselItem
            assetPath={musicBookImage}
            title={"Singing Sessions"}
            desc={
              "Singing sessions can be adapted to suit any age. Sessions are fun and a great way to express creativity, sing a variety of music and learn about different styles."
            }
          />
        </Carousel>
      </div>
    );
  }
}
export default CarouselContainer;
