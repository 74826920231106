import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "../Header/header";
import Footer from "../Footer/footer";
import PageHeading from "./pageHeading";
import SocialFeed from "./socialFeed";
import "./style.css";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="home">
        <Header></Header>
        <div className="pageContainer">
          <div className="ellipse1"></div>
          <PageHeading title="London-Based Music Facilitator, Educator and Performer"></PageHeading>
          <div className="flexContainer home">
            <div className="articleBox">
              <h2>
                Saxophonist,
                <br />
                Clarinettist
                <br />& Flautist
              </h2>
              <span className="articleContent">
                <p>
                  Graduating from the Royal College of Music, Jess Martin is a
                  freelance musician working and performing in a variety of
                  settings. Whether it be tutoring budding young musicians,
                  providing accessible music, or working with people who have
                  dementia, Jess is passionate about using music to help others.
                </p>
                <p>
                  Teaching music, both privately and in school, Jess currently
                  works for multiple music services and institutions as a
                  woodwind tutor and whole class instrumental teacher. As a
                  music facilitator, Jess regularly delivers music outreach
                  projects, working closely with organisations such as Turtle
                  Key Arts.
                </p>
              </span>
            </div>
            <div className="imageContainer">
              <div className="imageOne"></div>
              <div className="imageTwo"></div>
              <div className="imageThree"></div>
            </div>
          </div>
        </div>

        <div className="pageContainer teachingtutoring">
          <div className="ellipse2"></div>
          <PageHeading title="Teaching & Tutoring"></PageHeading>
          <div className="subHeadDesc">
            Passionate about music education, Jess takes pride in delivering
            tailored, inclusive and engaging music sessions. Working across
            London, she delivers one to one or small group instrumental sessions
            as well as whole class sessions as part of the music curriculum.
          </div>
          <div className="flexContainer">
            <div className="angledBox left">
              <Link to="/schools">
                <div className="boxTitle">School Teaching</div>
                <div className="boxDesc">
                  Working in a variety of Primary and Secondary schools across
                  London, Jess has experience teaching whole class instrumental
                  classes, small group and 1-1 lessons as well as running choirs
                  and orchestras.
                </div>

                <div className="arrow"></div>

                <div className="boxIconContainer">
                  <div className="instrumantIcon book"></div>
                </div>
              </Link>
            </div>
            <div className="angledBox right">
              <Link to="/tutoring">
                <div className="boxTitle">Private Tutoring</div>
                <div className="boxDesc">
                  Learning an instrument is a valuable skill. Private tutoring
                  allows you to learn at your own pace and master your new
                  talent from any level.
                </div>

                <div className="arrow"></div>

                <div className="boxIconContainer">
                  <div className="instrumantIcon sax"></div>
                </div>
              </Link>
            </div>
          </div>
        </div>
        <div className="pageContainer performanceSection">
          <PageHeading title="Performing & Outreach"></PageHeading>
          <div className="subHeadDesc">
            Concert halls, festivals, prisons, hospitals, community centres –
            nowhere is off-limits. Music should be accessible to everybody,
            everywhere and anywhere! From performing a chamber music concert to
            music in a residential home, Jess loves the variety of her work she
            does.
          </div>
          <div className="performance-button-container">
            <Link to="/performing">
              <div className="performance-button">
                Performance <span className="black-arrow"></span>
              </div>
            </Link>
            <Link to="/supporting">
              <div className="performance-button">
                Outreach <span className="black-arrow"></span>
              </div>
            </Link>
          </div>
          <div className="videoContainer">
            <div className="performance-blob1"></div>
            <div className="performance-blob2"></div>
          </div>
        </div>

        <div className="pageContainer">
          <SocialFeed></SocialFeed>
        </div>
        <Footer></Footer>
      </div>
    );
  }
}
export default Home;
